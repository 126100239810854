import React from "react";

import { graphql, navigate } from "gatsby";
import Img from "gatsby-image";

import Helmet from "react-helmet";
import * as styles from "./index.module.scss";

import { Button, Col, Row } from "antd";

import Footer from "@components/footer";
import Header from "@components/header";
import ImgScrollInBox from "@components/imgScrollInBox";

import pcImg from "@src/images/show/飞书20210924-194535.png";
import phoneImg from "@src/images/show/飞书20210924-194610.png";

const handleRegister = (event) => {
  event.preventDefault();
  navigate("/register/");
};

const ShowPage = ({ data }) => {
  const pcRenderData = [
    {
      img: data.theme1.childImageSharp.fluid,
      desc: "首饰模板",
    },
    {
      img: data.theme2.childImageSharp.fluid,
      desc: "家居模板",
    },
    {
      img: data.theme3.childImageSharp.fluid,
      desc: "鞋类模板",
    },
    {
      img: data.theme4.childImageSharp.fluid,
      desc: "彩妆模板",
    },
    {
      img: data.theme5.childImageSharp.fluid,
      desc: "配饰模板",
    },
    {
      img: data.theme6.childImageSharp.fluid,
      desc: "女装模板",
    },
  ];

  const phoneRenderData = [
    {
      img: data.theme1.childImageSharp.fluid,
      desc: "首饰模板",
    },
    {
      img: data.theme2.childImageSharp.fluid,
      desc: "家居模板",
    },
    {
      img: data.theme3.childImageSharp.fluid,
      desc: "鞋类模板",
    },
    {
      img: data.theme4.childImageSharp.fluid,
      desc: "彩妆模板",
    },
    {
      img: data.theme5.childImageSharp.fluid,
      desc: "配饰模板",
    },
    {
      img: data.theme6.childImageSharp.fluid,
      desc: "女装模板",
    },
  ];

  return (
    <div className={styles.showPage}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>模板展示-Shoprise 官网</title>
      </Helmet>
      <Header />

      <div className={styles.banner}>
        <picture>
          <source media="(min-width: 760px)" srcSet={pcImg} />
          <source media="(max-width: 760px)" srcSet={phoneImg} />
          <img src={pcImg} alt="pcImg" style={{ width: "100%" }} />
        </picture>
        <Button
          type="primary"
          shape="round"
          className={styles.primaryButton}
          onClick={handleRegister}
          style={{
            backgroundColor: "#0075FF",
          }}
        >
          准备好被自己惊艳了吗
        </Button>
      </div>
      <div>
        <Row>
          <Col xs={0} sm={0} md={24}>
            <div style={{ backgroundColor: "#fff" }}>
              <div
                style={{
                  maxWidth: "1200px",
                  margin: "0 auto",
                  padding: "60px 0",
                }}
              >
                <div className={styles.cardGroup}>
                  {pcRenderData.map((item) => (
                    <div className={styles.containerBox} key={item.desc}>
                      <ImgScrollInBox
                        width={336}
                        height={545}
                        imgUrl={item.img}
                      />
                      <p>{item.desc}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={0}>
            <div className={styles.smallBox}>
              {phoneRenderData.map((item) => (
                <div className={styles.smallBoxContainer} key={item.desc}>
                  <div className={styles.smallItem}>
                    {/* <img src={item.img} alt="" style={{ width: "100%" }} /> */}
                    <Img fluid={item.img} alt="phonebg" />
                  </div>
                  <p>{item.desc}</p>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>

      <Footer />
    </div>
  );
};
export default ShowPage;
//使用了来自components/cardList/index.jsx 里面定义的查询条件

export const query = graphql`
  query {
    theme1: file(relativePath: { eq: "theme/theme1.jpg" }) {
      ...themeImage
    }
    theme2: file(relativePath: { eq: "theme/theme6.jpg" }) {
      ...themeImage
    }
    theme3: file(relativePath: { eq: "theme/theme2.jpg" }) {
      ...themeImage
    }
    theme4: file(relativePath: { eq: "theme/theme3.jpg" }) {
      ...themeImage
    }
    theme5: file(relativePath: { eq: "theme/theme4.jpg" }) {
      ...themeImage
    }
    theme6: file(relativePath: { eq: "theme/theme5.jpg" }) {
      ...themeImage
    }
  }
`;
